<template>
    <modal ref="modalEditarImagenProducto" v-loading="loading" :titulo="'Editar imagen del producto'" no-aceptar adicional="Guardar" @adicional="editarImagen">
        <div class="d-flex flex-column justify-content-center align-items-center mx-0 my-3">
            <div class="col-auto text-center text-right">
                <p class="text-general f-14 text-center">
                    Imagen del producto
                </p>
                <slim-cropper ref="cropImagen" :options="slimOptions" class="border br-10" style="height:160px;width:160px;background:#F8F9FF;">
                    <div slot="img" class="miClase">
                        <img style="width:110px;" src="/img/modales/camera.svg" alt="" />
                    </div>
                </slim-cropper>
                <p class="f-14 text-general text-right">Aspecto 1:1</p>
            </div>
        </div>
    </modal>
</template>

<script>
import Aws from '~/services/aws'
import Productos from "~/services/productos/productosAdmin";

export default {
    data(){
        return {
            loading: false,
            model:{
                id: null,
                nombre: '',
                banner: ''
            },
            slimOptions: {
                ratio: '4:5',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            titulo: '',
            editar: false,
            imagen: {},
            id_producto: ''
        }
    },
    methods: {
        toggle(id_producto,foto){
            if(foto!=="/img/no-imagen/mercado.svg"){     
                this.$refs.cropImagen.instanciaCrop.load(foto)
                
            }else{
                this.$refs.cropImagen.instanciaCrop.remove()
            }
            this.$refs.modalEditarImagenProducto.toggle()
            this.id_producto = id_producto
        },
        limpiar(){
            this.model = {
                id: null,
                nombre: '',
                banner: ''
            }
            this.$refs.cropImagen.instanciaCrop.remove()
        },
        async editarImagen(){
            this.imagen = this.$refs.cropImagen.instanciaCrop.dataBase64.output.image
            let  params = {
                imagen: this.imagen? this.imagen : null 
            }
            try {
                const { data } = await Productos.editarImagenProducto(this.id_producto,params)
            } catch (e){
                this.error_catch(e)
            }
            this.$refs.modalEditarImagenProducto.toggle()
            this.notificacion("","Imagen actualizada con éxito","success")
            const lastId = this.id_producto
            this.$store.commit('productos/productos/set_id_producto', null)
            setTimeout(() => {
                this.$store.commit('productos/productos/set_id_producto', lastId)
            }, 100)
            this.imagen = ''
        }
    }
}
</script>
